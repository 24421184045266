export const mintData = [
  {
      img: "/home_page/pro_miles_3.png",
      content: "EARN $HERO & $VILLAIN",
      link: "/stake",
      label: "STAKE NOW!",
      width: "w-3/4"
  },
  {
      img: "/home_page/pro_miles_4.png",
      content: "ORIGINS: SOURCEBOOK",
      link: "/comic",
      label: "VIEW COMICS",
      width: "w-3/4"
  },
  {
      img: "/home_page/up_miles_1.png",
      content: "SIDEKICKS & HENCHMAN",
      link: "#",
      label: "COMING SOON",
      width: "w-1/2"
  },
];

export const upcomingData = [
  {
      img: "/home_page/up_miles_1.png",
      content: "Sidekicks & Henchmen",
      status: "Mint",
      width: "w-1/2",
      isChecked: false
  },
  {
      img: "/home_page/up_miles_un2.png",
      content: "S&H: Sourcebook",
      status: "Comic",
      width: "w-2/3",
      isChecked: false
  },
  {
      img: "/home_page/up_miles_2.png",
      content: "$HV Launchpads & TGE",
      status: "Token",
      width: "w-2/3",
      isChecked: false
  },
  {
      img: "/home_page/up_miles_3.png",
      content: "Extreme Measures",
      status: "P2E Game",
      width: "w-4/5",
      isChecked: false
  },
  {
      img: "/home_page/up_miles_un2.png",
      content: "Extreme Measures",
      status: "Comic",
      width: "w-2/3",
      isChecked: false
  },
  {
      img: "/home_page/up_miles_un1.png",
      content: "Mutants Expansion",
      status: "Mint",
      width: "w-4/5",
      isChecked: false
  },
  {
      img: "/home_page/up_miles_un1.png",
      content: "Alliances Expansion",
      status: "Mint",
      width: "w-4/5",
      isChecked: false
  }
];

export const projectData = [
  {
      img: "/home_page/pro_miles_1.png",
      content: "HeroPass & VillainPass",
      status: "Mint",
      width: "w-2/3",
      isChecked: true
  },
  {
      img: "/home_page/pro_miles_2.png",
      content: "Origins Collection",
      status: "Mint",
      width: "w-4/5",
      isChecked: true
  },
  {
      img: "/home_page/pro_miles_3.png",
      content: "$HERO & $VILLAIN",
      status: "Staking",
      width: "w-2/3",
      isChecked: true
  },
  {
      img: "/home_page/pro_miles_4.png",
      content: "Origins: Sourcebook",
      status: "Comic",
      width: "w-2/3",
      isChecked: true
  }
];

export const teamData = [
  {
      img: "/home_page/team_jay.png",
      name: "JAY DEFOY",
      role: "Pencils / Villain",
      content: "Putting pencil to paper, Jay has a knack for transforming fantasy and even the most impossible of ideas, into reality. He lays the all-important foundation upon which, Mark and Ross build.",
      twitter: "https://twitter.com/DefoyJay"
  },
  {
      img: "/home_page/team_mark.png",
      name: "MARK MCKENNA",
      role: "Inks / Legend",
      content: "With over 30 years in the business, the comic industry legend (Marvel, DC) has over 1,000 credits to his name and like a fine wine, just gets better with age. This all exists because of him.",
      twitter: "https://twitter.com/MarkMcKenna419"
  },
  {
      img: "/home_page/team_ross.png",
      name: "ROSS CAMPBELL",
      role: "Colors / Hero",
      content: "Colorist supreme, Ross is a comic book pro, video game enthusiast and podcast co-host. He completes the Heroes & Villains dream team. Can't get enough? Checkout Crowfolk, Ross' upcoming universe.",
      twitter: "https://twitter.com/Ross_A_Campbell"
  },
  {
      img: "/home_page/team_keith.png",
      name: "KEITH BUSSEY",
      role: "Founder / Masterbrews",
      content: "Wielding the Gauntlet is no easy task, but Keith loves nothing more than a good challenge! Veteran CTO of 20+ years, his strengths lie in a rare combination of tech prowess and business creativity.",
      twitter: "https://twitter.com/MasterBrewsNFT"
  },
  {
      img: "/home_page/team_adam.png",
      name: "ADAM HUDANI",
      role: "Founder / Masterbrews",
      content: "Previous to co-founding MasterBrews, Adam has ran operations for numerous successful companies in both the private and public sectors. His core experiences include business operations and marketing.",
      twitter: "https://twitter.com/TiltingS"
  },
  {
      img: "/home_page/team_jason.png",
      name: "JASON SILVERT",
      role: "Sidekick / Masterbrews",
      content: "Jason has extensive experience with customer relations and community management. Prior to MasterBrews, Jason held a long tenured position with Apple, driving a number of highly successful customer support initiatives.",
      twitter: "https://twitter.com/ThePenguinVA"
  }
];

export const faqMintData = [
  {
    qnumber: "Q1",
    title: "How much does each NFT cost?",
    content:
      "Mints are priced at 1.5 SOL each. If you hold a HeroPass or VillainPass, then you save 10% and pay just 1.35 SOL each.",
  },
  {
    qnumber: "Q2",
    title: "Do I get to select if I want a Hero or Villain?",
    content:
      "No. Each mint goes through six stages of evolution which ultimately (and randomly) determines their final alignment.",
  },
  {
    qnumber: "Q3",
    title: "How long does each evolutionary phase take?",
    content: "72 hours.",
  },
  {
    qnumber: "Q4",
    title: "How many NFTs are available for mint?",
    content: "5000, of which less than 2000 currently remain.",
  },
  {
    qnumber: "Q5",
    title: "What are the mint limits?",
    content:
      "Mints are limited to 1 per transaction, with no further limit on how many an individual wallet can own.",
  },
  {
    qnumber: "Q6",
    title: "What do royalties look like?",
    content:
      "Royalties are set at 5%. They will be split between the Creators and Community and used to fund ongoing project development.",
  },
  {
    qnumber: "Q7",
    title: "What wallet should I use?",
    content:
      "While we do support the most popular Solana options, we highly recomend Phantom wallet.",
  },
  {
    qnumber: "Q8",
    title: "What do I do if I have unanswered questions?",
    content:
      "Join us in Discord where our team and community will be happy to help you out!",
  },
]

export const categoryData = [
  {
    name: "Alignment",
    filter: ["Hero", "Villain", "Superhero", "Supervillain"],
  },
  {
    name: "Artifact",
    filter: [
      "Amulet (Ancient)",
      "Amulet (Norse Hammer)",
      "Amulet (Ornamental)",
      "Amulet (Ruby)",
      "Amulet (Shamanic)",
      "Amulet (Tech)",
      "Bionic Eye",
      "Book",
      "Crystal",
      "Gas Mask",
      "Icosahedron",
      "Infernal Horn",
      "Lantern",
      "Mempo",
      "Ouroboros",
      "Power Cube",
      "Ring (Angelic)",
      "Ring (Diamond)",
      "Ring (Serpent)",
      "Ring (Templar Cross)",
      "Ring (Vessel)",
      "Rosary",
      "Skull"
    ],
  },
  {
    name: "Background",
    filter: [
      "Abandoned Factory",
      "City Rooftop",
      "Desolate Monastery",
      "Drakkar Shipwreck",
      "Egyptian Tomb",
      "Government Lab",
      "Japanese Shrine",
      "Mayan Temple",
      "Meteor Shower",
      "Mount Olympus",
      "Nuclear Power Plant",
      "Prison Cell",
      "Seaside Castle Ruins",
      "The Brewhouse",
      "The Moon",
      "Underworld Clinic"
    ],
  },
  {
    name: "Eye Color",
    filter: [
      "Amber",
      "Blue",
      "Blue Steel",
      "Brown",
      "Cold Stone",
      "Electric Blue",
      "Glowing",
      "Gray",
      "Green",
      "Hazel",
      "Heterochromia",
      "Magenta",
      "Red",
      "Slate",
      "Yellow"
    ],
  },
  {
    name: "Facial Expression",
    filter: [
      "Happy",
      "Hungry",
      "Poker Face",
      "Smug",
      "Stoic"
    ],
  },
  {
    name: "Facial Features",
    filter: [
      "Earring",
      "Eyebrow Stud",
      "Freckles",
      "Nose Ring",
      "Scar (cheek)",
      "Scar (eye)",
      "Scar (nose)",
      "Stud"
    ],
  },
  {
    name: "Facial Hair",
    filter: [
      "Beard (long)",
      "Beard (medium)",
      "Beard (short)",
      "Goatee",
      "Moustasche"
    ],
  },
  {
    name: "Facial Hair Color",
    filter: [
      "Black",
      "Blond",
      "Brown",
      "Dark Brown",
      "Ginger",
      "Gray",
      "Platinum",
      "Scottish Red",
      "White"
    ],
  },
  {
    name: "Gender",
    filter: [
      "Male", 
      "Female", 
      "Non-Binary"
    ],
  },
  {
    name: "Hair Color",
    filter: [
      "Black",
      "Blond",
      "Brown",
      "Copper",
      "Dark Brown",
      "Ginger",
      "Gray",
      "Nightshade",
      "Pink & Green",
      "Platinum",
      "Scarlet",
      "Scottish Red",
      "Silver",
      "White"
    ],
  },
  {
    name: "Hairstyle",
    filter: [
      "Bald",
      "Bob",
      "Bun",
      "Buzzcut",
      "Cyberpunk",
      "Dreadlocks",
      "Long Flowing",
      "Long Wavey",
      "Long Wavey (shaved)",
      "Longtop",
      "Medium Flow",
      "Messy Curl",
      "Pony Tail",
      "Short",
      "Short Curls",
      "Short Cut",
      "Shoulder Length"
    ],
  },
  {
    name: "Organization",
    filter: [
      "S.A.F.E"
    ],
  },
  {
    name: "Outfit",
    filter: [
      "Barbarian Belt",
      "Cassock (dark)",
      "Cassock (light)",
      "Chainmail Bikini",
      "Corset",
      "Costume",
      "Crop (black)",
      "Crop (blue)",
      "Crop (green)",
      "Crop (red)",
      "Crop (white)",
      "Egyptian Priestess",
      "Enhanced Biosuit",
      "Forest Priestess",
      "Greek God",
      "Hoodie (black)",
      "Hoodie (blue)",
      "Hoodie (green)",
      "Hoodie (red)",
      "Hoodie (white)",
      "Infernal Robes",
      "Leather Jacket",
      "Longbottom (black)",
      "Longbottom (blue)",
      "Longbottom (red)",
      "Longbottom (white)",
      "Longsleeve (black)",
      "Longsleeve (blue)",
      "Longsleeve (green)",
      "Longsleeve (red)",
      "Longsleeve (white)",
      "Nun Habit",
      "ShortTee (black)",
      "ShortTee (blue)",
      "ShortTee (green)",
      "ShortTee (red)",
      "ShortTee (white)",
      "Sleeveless (black)",
      "Sleeveless (blue)",
      "Sleeveless (green)",
      "Straps (black)",
      "Straps (blue)",
      "Straps (green)",
      "Straps (red)",
      "Straps (white)",
      "Suit (black)",
      "Suit (blue)",
      "Suit (white)",
      "Swashbuckler",
      "TankTop (black)",
      "TankTop (blue)",
      "TankTop (green)",
      "TankTop (red)",
      "TankTop (white)",
      "TeeShirt (black)",
      "TeeShirt (blue)",
      "TeeShirt (green)",
      "TeeShirt (red)",
      "TeeShirt (white)",
      "Trenchcoat",
      "Wraps"
    ],
  },
  {
    name: "Power",
    filter: [
      "Armour (Holy)",
      "Armour (Mech)",
      "Armour (Unholy)",
      "Armour (Tech)", 
      "Consecration",
      "Dimensional Door",
      "Elemental Manipulation (Cosmic)",
      "Elemental Manipulation (Dark)",
      "Elemental Manipulation (Earth)",
      "Elemental Manipulation (Fire)",
      "Elemental Manipulation (Holy)",
      "Elemental Manipulation (Lightning)",
      "Elemental Manipulation (Water)",
      "Flight (Celestial)",
      "Flight (Infernal)",
      "Flight (Mech)",
      "Forcefield",
      "Invisibility",
      "Invulnerability (Steel)",
      "Invulnerability (Stone)",
      "Portal",
      "Raise Undead",
      "Rewind",
      "Spectral Familiar (Arpax)",
      "Spectral Familiar (Draco)",
      "Spectral Familiar (Lupus)",
      "Spectral Familiar (Rex)",
      "Spectral Familiar (Ursus)",
      "Spirit Prison",
      "Super Speed",
      "Super Strength",
      "Supreme Intellect",
      "Telepathy",
      "Teleportation",
      "Thermal Vision",
      "Time Travel",
      "Undeath"
    ],
  },
  {
    name: "Race",
    filter: [
      "Alien", 
      "Archdemon", 
      "Atlantean", 
      "Celestial", 
      "Dark Elf", 
      "Darkwater Merfolk",
      "Demon",
      "Elf",
      "Human",
      "Light Elf",
      "Merfolk",
      "Rogue Shark",
      "Seraphim",
      "The Ascended",
      "The Fallen",
      "The Greys",
      "The Reptilians"
    ],
  },
  {
    name: "Skin Tone",
    filter: [
      "Amber",
      "Black",
      "Brown",
      "Gray",
      "Great White",
      "Grey",
      "Light Caramel",
      "Olive",
      "PPale Ivory",
      "Pale Ivory",
      "Purple",
      "Red",
      "Sand",
      "Steel",
      "Stone",
      "Tan",
      "Translucent",
      "Warm Ivory"
    ],
  },
  {
    name: "Weapon",
    filter: [
      "Adorned Staff",
      "Alien Blade",
      "Antique Spear",
      "Battle Axe",
      "Battle Scepter",
      "Bone Saw",
      "Chain Dagger",
      "Cleaver",
      "Cross Blade",
      "Cryptoknight Dagger",
      "Dagger",
      "Ecclesiastical Scepter",
      "Godzilla Claw",
      "Hatchet",
      "Heavy Hammer",
      "Hero's Axe",
      "Hero's Bow",
      "Hero's Polearm",
      "Hero's Spear",
      "Hero's Sword",
      "Hunting Spear",
      "Improvised Nunchaku",
      "Iridium Gauntlet",
      "Junk Bow",
      "Katana",
      "Lead Pipe",
      "Macuahuitl",
      "Mech Arm",
      "Meteor Dagger",
      "Morningstar",
      "Mutation",
      "Obsidian Sword",
      "Pharaoh's Heka",
      "Plasma Dagger",
      "Power Gauntlet",
      "Razor Whip",
      "Robust Sword",
      "Scepter of Hermes",
      "Seer's Staff",
      "Sickle",
      "Spiked Club",
      "Tech Bow",
      "Tech Scepter",
      "Tech Spear",
      "Traditional Bow",
      "Villain's Axe",
      "Villain's Bow",
      "Villain's Polearm",
      "Villain's Spear",
      "Villain's Sword",
      "War Hammer",
      "Winged Sword"
    ],
  },
]

export const nftData = [
  {
    image: "/gallery_page/Image1.png",
    name: "Alice",
  },
  {
    image: "/gallery_page/Image2.png",
    name: "Alice",
  },
  {
    image: "/gallery_page/Image3.png",
    name: "Alice",
  },
  {
    image: "/gallery_page/Image4.png",
    name: "Alice",
  },
  {
    image: "/gallery_page/Image5.png",
    name: "Alice",
  },
  {
    image: "/gallery_page/Image6.png",
    name: "Alice",
  },
  {
    image: "/gallery_page/Image1.png",
    name: "Alice",
  },
  {
    image: "/gallery_page/Image2.png",
    name: "Alice",
  },
  {
    image: "/gallery_page/Image3.png",
    name: "Alice",
  },
  {
    image: "/gallery_page/Image3.png",
    name: "Alice",
  }
]

export const guideData = [
  {
    name: "ALIEN BLADE",
    type: "weapon",
    image: "/mint_page/blade_1.png",
    description:
      "LOREM IPSUM IS SIMPLY DUMMY TEXT OF THE PRINTING AND TYPE SET INDUSTRY. LOREM IPSUM HAS BEEN THE INDUSTRY'S STANDARD",
  },
  {
    name: "ALIEN BLADE",
    type: "weapon",
    image: "/mint_page/blade_2.png",
    description:
      "LOREM IPSUM IS SIMPLY DUMMY TEXT OF THE PRINTING AND TYPE SET INDUSTRY. LOREM IPSUM HAS BEEN THE INDUSTRY'S STANDARD",
  },
  {
    name: "BASIC SPEAR",
    type: "weapon",
    image: "/mint_page/blade_3.png",
    description:
      "LOREM IPSUM IS SIMPLY DUMMY TEXT OF THE PRINTING AND TYPE SET INDUSTRY. LOREM IPSUM HAS BEEN THE INDUSTRY'S STANDARD",
  },
  {
    name: "ABANDONED FACTORY",
    type: "artifact",
    image: "/mint_page/artifact_1.png",
    description:
      "LOREM IPSUM IS SIMPLY DUMMY TEXT OF THE PRINTING AND TYPE SET INDUSTRY. LOREM IPSUM HAS BEEN THE INDUSTRY'S STANDARD",
  },
  {
    name: "CITY ROOFTOP",
    type: "artifact",
    image: "/mint_page/artifact_2.png",
    description:
      "LOREM IPSUM IS SIMPLY DUMMY TEXT OF THE PRINTING AND TYPE SET INDUSTRY. LOREM IPSUM HAS BEEN THE INDUSTRY'S STANDARD",
  },
  {
    name: "DESOLATE MONASTERY",
    type: "artifact",
    image: "/mint_page/artifact_3.png",
    description:
      "LOREM IPSUM IS SIMPLY DUMMY TEXT OF THE PRINTING AND TYPE SET INDUSTRY. LOREM IPSUM HAS BEEN THE INDUSTRY'S STANDARD",
  },
  {
    name: "ABANDONED FACTORY",
    type: "power",
    image: "/mint_page/power_1.png",
    description:
      "LOREM IPSUM IS SIMPLY DUMMY TEXT OF THE PRINTING AND TYPE SET INDUSTRY. LOREM IPSUM HAS BEEN THE INDUSTRY'S STANDARD",
  },
  {
    name: "CITY ROOFTOP",
    type: "power",
    image: "/mint_page/power_2.png",
    description:
      "LOREM IPSUM IS SIMPLY DUMMY TEXT OF THE PRINTING AND TYPE SET INDUSTRY. LOREM IPSUM HAS BEEN THE INDUSTRY'S STANDARD",
  },
  {
    name: "DESOLATE MONASTERY",
    type: "power",
    image: "/mint_page/power_3.png",
    description:
      "LOREM IPSUM IS SIMPLY DUMMY TEXT OF THE PRINTING AND TYPE SET INDUSTRY. LOREM IPSUM HAS BEEN THE INDUSTRY'S STANDARD",
  },
  {
    name: "ABANDONED FACTORY",
    type: "location",
    image: "/mint_page/city_1.png",
    description:
      "LOREM IPSUM IS SIMPLY DUMMY TEXT OF THE PRINTING AND TYPE SET INDUSTRY. LOREM IPSUM HAS BEEN THE INDUSTRY'S STANDARD",
  },
  {
    name: "CITY ROOFTOP",
    type: "location",
    image: "/mint_page/city_2.png",
    description:
      "LOREM IPSUM IS SIMPLY DUMMY TEXT OF THE PRINTING AND TYPE SET INDUSTRY. LOREM IPSUM HAS BEEN THE INDUSTRY'S STANDARD",
  },
  {
    name: "DESOLATE MONASTERY",
    type: "location",
    image: "/mint_page/city_3.png",
    description:
      "LOREM IPSUM IS SIMPLY DUMMY TEXT OF THE PRINTING AND TYPE SET INDUSTRY. LOREM IPSUM HAS BEEN THE INDUSTRY'S STANDARD",
  },
  {
    name: "ALIEN",
    type: "race",
    image: "/mint_page/race_1.png",
    description:
      "LOREM IPSUM IS SIMPLY DUMMY TEXT OF THE PRINTING AND TYPE SET INDUSTRY. LOREM IPSUM HAS BEEN THE INDUSTRY'S STANDARD",
  },
  {
    name: "CELESTIAL",
    type: "race",
    image: "/mint_page/race_2.png",
    description:
      "LOREM IPSUM IS SIMPLY DUMMY TEXT OF THE PRINTING AND TYPE SET INDUSTRY. LOREM IPSUM HAS BEEN THE INDUSTRY'S STANDARD",
  },
  {
    name: "DEMON",
    type: "race",
    image: "/mint_page/race_3.png",
    description:
      "LOREM IPSUM IS SIMPLY DUMMY TEXT OF THE PRINTING AND TYPE SET INDUSTRY. LOREM IPSUM HAS BEEN THE INDUSTRY'S STANDARD",
  },
];

export const safeHumans = [2500,2502,2503,2504,2505,2507,2508,2509,2514,2515,2516,2517,2518,2523,2527,2531,2536,2537,2538,2539,2544,2547,2548,2550,2552,2553,2555,2556,2557,2562,2563,2565,2567,2572,2573,2575,2577,2579,2582,2584,2585,2586,2587,2588,2589,2596,2599,2600,2601,2602,2604,2606,2607,2608,2609,2610,2611,2612,2613,2614,2615,2617,2618,2619,2620,2623,2627,2630,2631,2634,2637,2643,2644,2647,2648,2651,2657,2658,2661,2663,2668,2669,2671,2672,2673,2676,2677,2678,2680,2681,2683,2684,2686,2689,2691,2694,2695,2698,2701,2703,2705,2709,2712,2713,2714,2718,2722,2724,2728,2732,2733,2735,2738,2739,2744,2749,2750,2751,2755,2757,2761,2762,2765,2767,2769,2771,2772,2775,2778,2779,2780,2782,2786,2790,2791,2793,2796,2798,2800,2802,2812,2816,2817,2818,2819,2821,2823,2826,2832,2835,2841,2842,2847,2848,2849,2850,2855,2859,2861,2862,2864,2866,2867,2869,2871,2874,2875,2878,2879,2880,2881,2882,2888,2889,2898,2899,2902,2908,2911,2913,2918,2922,2927,2929,2937,2938,2947,2952,2960,2963,2964,2972,2975,2977,2980,2982,2995,2997,3007,3009,3013,3021,3022,3026,3028,3032,3040,3044,3046,3049,3054,3056,3057,3060,3063,3065,3068,3077,3078,3081,3083,3085,3093,3094,3096,3103,3106,3110,3112,3118,3125,3129,3133,3135,3136,3140,3141,3143,3149,3172,3174,3178,3194,3199,3200,3205,3215,3250,3270,3277,3281,3303,3310,3324,3325,3327,3344,3345,3366,3370,3375,3376,3399,3403,3414,3426,3438,3442,3452,3470,3485,3493,3495,3501,3511,3516,3520,3527,3529,3541,3543,3556,3567,3575,3576,3604,3608,3615,3618,3620,3633,3640,3644,3655,3656,3663,3664,3668,3679,3687,3697,3698,3701,3712,3717,3719,3723,3724,3725,3729,3743,3744,3748,3763,3769,3784,3791,3797,3822,3827,3841,3884,3886,3951,3981,4033,4037,4046,4051,4091,4181,4188,4189,4232,4263,4266,4321,4330,4386,4454,4467,4492,4539,4553,4555,4616,4629,4640,4655,4686,4691,4731,4746,4757,4766,4772,4773,4812,4835,4842,4893,4896,4913,4916,4918,4926,4939,4943,4945,4953,4969,4981,4984,4988,4991];
